<template>
  <div>
    <el-dialog
      :visible.sync="myOpen"
      title="Tips"
      :width="width"
      :show-close="false"
      :custom-class="fullscreen ? 'default-dialog-fullscreen' : 'default-dialog'"
      :top="top"
      :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape"
      :destroy-on-close = destroy
      :fullscreen="fullscreen"
    >
      <template v-slot:title>
        <slot name="title">
          <div class="default-dialog-title-box text-center">
            <el-row>
                <h3 class="default-dialog-title">{{ title }}</h3>
            </el-row>
          </div>
        </slot>
      </template>

      <slot>
        <el-row>
            <div class="text-center" v-html="body"></div>
        </el-row>
      </slot>

      <template v-slot:footer>
        <p
          class="default-dialog-close text-center"
          @click="close()"
        >
          Entendi
        </p>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'DialogDefault',
  props: {
    title: {
      type: String,
      default: ''
    },
    body: {
      type: String
    },
    destroy: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    },
    top: {
      type: String,
      default: '15vh'
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    open (newValue) {
      this.myOpen = newValue
    },
    myOpen (newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.close()
      }
    }
  },
  data () {
    return {
      myOpen: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.myOpen = this.open
    },
    close () {
      this.$emit('update:open', false)
      this.$emit('close', false)
    }
  }
}
</script>
<style>
.default-dialog {
  background: #FFFFFF 0 0 no-repeat padding-box;
  border-radius: 8px;
  /*margin-top: 5vh !important;*/
  z-index: 999999;
}
</style>

<style scoped>
.default-dialog-title-box {
  padding-bottom: 12px;
  border-bottom: 1px solid #CCCCCC;
}

.default-dialog-title {
  font: normal normal 600 24px/29px Open Sans;
  font-weight: 600;
  letter-spacing: 0;
  color: #333333;
}

.default-dialog-close {
  font: normal normal 600 13px/16px Open Sans;
  font-weight: 600;
  letter-spacing: 0;
  color: #1074FF;
  cursor: pointer;
}

.default-dialog-footer-box {
  padding: 10px;
  border-top: 1px solid #CCCCCC;
}

.default-dialog-container {
  overflow: auto;
  /*max-height: 70vh;*/
  max-height: 50vh;
}
</style>
