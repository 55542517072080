import { getField, updateField } from 'vuex-map-fields'
import { findBeneficiarios } from '@mobilesaude/frontend_libs/helpers/usuario'

const initialState = () => ({
  beneficiarioCompleto: {}
})

const state = initialState

const getters = {
  getField,
  contratos: state => state.beneficiarioCompleto.contratos || {},
  user: state => state.beneficiarioCompleto.usuarioLogado || {},
  beneficiarios: state => findBeneficiarios(state.beneficiarioCompleto) || {}
}

const actions = {}

const mutations = {
  updateField,
  SET_LOGIN (state, payload) {
    state.beneficiarioCompleto = payload
  },
  INITIAL_STATE (state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  SET_BENEFICIARIO_USER_PERMISSOES (state, payload) {
    state.beneficiarioCompleto.usuarioLogado.permissoes = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
